import React, { useState } from 'react';
import {
  useGetIdentity,
  useGetLocale,
  useSetLocale,
  useLogout,
  useIsExistAuthentication,
} from '@pankod/refine-core';
import { AppBar, Avatar, Stack, IconButton } from '@pankod/refine-mui';
import logo_polyrec from '../../../assets/logo_polyrec.svg';

import { Logout } from '@mui/icons-material';

export const Header: React.FC = () => {
  const changeLanguage = useSetLocale();
  const locale = useGetLocale();
  const currentLocale = locale();
  const { mutate: mutateLogout } = useLogout();
  const isExistAuthentication = useIsExistAuthentication();
  const [collapsed, setCollapsed] = useState(false);
  const { data: user } = useGetIdentity();
  const showUserInfo = user && (user.email || user.avatar);

  return (
    <AppBar
      color="default"
      position="sticky"
      elevation={0}
      sx={{
        height: 112,
        backgroundColor: '#fff',
        borderBottomRightRadius: '50px',
        borderBottomLeftRadius: '50px',
        width: '100%',
        py: 4,
      }}
    >
      <Stack
        direction="row"
        width="100%"
        justifyContent="center"
        alignItems="center"
        gap={6}
      >
        {showUserInfo && (
          <Stack direction="row" gap="16px" alignItems="center">
            <Avatar>
              {user.email.slice(0, user.email.indexOf('@'))[0].toUpperCase()}
            </Avatar>
          </Stack>
        )}
        <div style={{ width: 160 }}>
          <img src={logo_polyrec} alt="Polyrec Logo" />
        </div>
        <IconButton
          aria-label="logout"
          key="logout"
          onClick={() => mutateLogout()}
          sx={{ color: 'common.black' }}
        >
          <Logout />
        </IconButton>
      </Stack>
    </AppBar>
  );
};
