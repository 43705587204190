import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Slider,
  Stack,
  Typography,
} from '@pankod/refine-mui';
import React, { useEffect, useState } from 'react';
import RestoreIcon from '@mui/icons-material/Restore';
import { History } from './History';
import { Bin } from './Bin';
import {
  IResourceComponentsProps,
  useGetIdentity,
  useOne,
} from '@pankod/refine-core';
import { IBin, ISector } from 'interfaces/common';
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api';
import { DirectionsRenderer } from '@react-google-maps/api';
import Car from '../assets/icons/car.svg';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import DirectionsIcon from '@mui/icons-material/Directions';
import QrCodeIcon from '@mui/icons-material/QrCode';

function valuetext(value: number) {
  return `${value}%`;
}

const marks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 10,
    label: '10%',
  },
  {
    value: 20,
    label: '20%',
  },
  {
    value: 30,
    label: '30%',
  },
  {
    value: 40,
    label: '40%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 60,
    label: '60%',
  },
  {
    value: 70,
    label: '70%',
  },
  {
    value: 80,
    label: '80%',
  },
  {
    value: 90,
    label: '90%',
  },
  {
    value: 100,
    label: '100%',
  },
];
export const MainScreen: React.FC<IResourceComponentsProps> = () => {
  const [value, setValue] = React.useState('collect');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  /////////////

  const { data: user } = useGetIdentity();
  console.log(user);
  console.log(
    user?.driver?.secteurs?.map((secteur: ISector) => secteur?.bins)?.flat()
  );
  ////////
  const { isLoaded } = useJsApiLoader({
    // googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
    googleMapsApiKey: 'AIzaSyAZQW0QFcxlUKvDZnBf_xxsgiQNYdPM32M',
  });
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [error, setError] = useState<string | null>(null);
  const [directions, setDirections] = useState(null);
  const [open, setOpen] = React.useState(true);
  const [values, setValues] = React.useState<number>(50);
  const getColor = (val: number) => {
    if (val <= 50) {
      return 'primary.main';
    } else if (val <= 70) {
      return 'secondary.orange';
    } else {
      return 'secondary.main';
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  // test driver
  // const [id, setId] = useState(1);

  // const { data, isLoading, isError } = useOne({
  //   resource: 'drivers',
  //   id,
  // });

  // const driver = data?.data;
  // console.log(driver);
  //
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCenter({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  }, []);

  const handleLocationButtonClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCenter({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };

  ////////
  // Direction

  function handleDirections(
    response: google.maps.DirectionsResult | any,
    status: google.maps.DirectionsStatus
  ) {
    if (status === 'OK') {
      setDirections(response);
    } else {
      setError(status);
    }
  }

  function stopDirections() {
    setDirections(null);
  }

  async function getDirections() {
    if (user?.driver?.secteurs?.map((secteur: ISector) => secteur?.bins)) {
      const directionsService = new google.maps.DirectionsService();

      const bins: IBin[] = (
        user?.driver?.secteurs
          ?.map((secteur: ISector) => secteur?.bins)
          ?.flat() || []
      )?.filter((bin: IBin) => {
        const level: number =
          (bin?.citizens_requests
            ?.map((fill: { percentage?: number }) => fill.percentage)
            ?.pop() as number) || 0;
        return level >= values;
      });
      console.log(bins);
      const waypoints = bins?.map((bin) => ({
        location: { lat: Number(bin?.latitude), lng: Number(bin?.longitude) },
        stopover: true,
      }));
      console.log(waypoints);
      const origin = new google.maps.LatLng(center.lat, center.lng);

      // Calculate distance from origin to each bin using Google Maps Distance Matrix API
      type DistanceMatrixResponse = google.maps.DistanceMatrixResponse;
      const distanceMatrixService = new google.maps.DistanceMatrixService();
      const distanceMatrix = await new Promise<DistanceMatrixResponse | null>(
        (resolve) => {
          distanceMatrixService.getDistanceMatrix(
            {
              origins: [origin],
              destinations: bins.map((bin) => ({
                lat: Number(bin.latitude),
                lng: Number(bin.longitude),
              })),
              travelMode: google.maps.TravelMode.DRIVING,
            },
            (response, status) => {
              if (status === 'OK') {
                resolve(response);
              } else {
                console.error(
                  `Distance matrix service failed due to ${status}`
                );
                resolve(null);
              }
            }
          );
        }
      );

      if (!distanceMatrix) {
        console.error('Failed to retrieve distance matrix');
        return;
      }

      // Sort bins array by distance in descending order
      const distanceBins = bins.map((bin, index) => ({
        distance: distanceMatrix.rows[0].elements[index]?.distance?.value,
        index,
      }));
      const sortedBins = distanceBins
        .sort((a, b) => (b.distance || 0) - (a.distance || 0))
        .map((bin) => bins[bin.index]);

      const farthestBin = sortedBins[0];
      const destination = new google.maps.LatLng(
        Number(farthestBin?.latitude),
        Number(farthestBin?.longitude)
      );

      try {
        await directionsService.route(
          {
            origin: origin,
            destination: destination,
            waypoints: waypoints,
            travelMode: google.maps.TravelMode.DRIVING,
          },
          handleDirections
        ); // call handleDirections here instead of setDirections
      } catch (e) {
        console.error(`Directions service failed due to ${e}`);
      }
    }
  }

  //////
  const renderPage = () => {
    switch (value) {
      case 'history':
        return <History />;
      case 'collect':
        return (
          <>
            <Box
              component="div"
              sx={{
                width: '100%',
                height: '80vh',
                borderRadius: 2,
                border: 2,
                borderColor: '#E9E9E9',
                overflow: 'hidden',
              }}
            >
              <>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  sx={{
                    width: '100%',
                  }}
                  fullWidth
                >
                  <DialogTitle sx={{ mt: 2 }}>
                    <Typography
                      fontSize={16}
                      fontWeight={700}
                      color="primary.main"
                      textAlign="center"
                    >
                      COMMENCER LA COLLECTE
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    <Slider
                      // {...register('percentage')}
                      name="percentage"
                      sx={{
                        width: 310,
                        height: 10,
                        color: getColor(values),
                      }}
                      aria-label="FillLevel"
                      //  value={value}
                      onChange={(event: Event, newValue: number | number[]) => {
                        setValues(newValue as number);
                      }}
                      defaultValue={50}
                      getAriaValueText={valuetext}
                      valueLabelDisplay="auto"
                      step={10}
                      marks={marks}
                      min={0}
                      max={100}
                    />
                  </DialogContent>
                  <DialogActions sx={{ mb: 2, gap: 2 }}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleClose();
                        stopDirections();
                      }}
                      sx={{
                        bgcolor: '#FFF',
                        color: '#000',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                        py: '10px',
                        px: '20px',
                        width: '100%',
                        '&:hover': {
                          bgcolor: '#FFF',
                        },
                        '&:active': {
                          bgcolor: '#FFF',
                        },
                      }}
                    >
                      ANNULER
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: 'uppercase',
                        py: '10px',
                        px: '20px',
                        width: '100%',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                      }}
                      onClick={() => {
                        getDirections();
                        setOpen(false);
                      }}
                    >
                      COMMENCER
                    </Button>
                  </DialogActions>
                </Dialog>
                {isLoaded && (
                  <GoogleMap
                    center={{
                      lat: center?.lat,
                      lng: center?.lng,
                    }}
                    zoom={14}
                    mapContainerStyle={{
                      width: '100%',
                      height: '100vh',
                    }}
                    options={{
                      zoomControl: false,
                      streetViewControl: false,
                      mapTypeControl: false,
                      fullscreenControl: false,
                      // mapId: '80d3b0a42b8e1d11',
                    }}
                  >
                    <Marker
                      position={center}
                      icon={{
                        url: Car,
                        scaledSize: new window.google.maps.Size(50, 50),
                      }}
                    />

                    {/* {user?.driver?.secteur?.bins?.map((bin: IBin) => (
                      <Marker
                        key={bin.id}
                        position={{
                          lat: Number(bin?.latitude),
                          lng: Number(bin?.longitude),
                        }}
                      />
                    ))} */}
                    {user?.driver?.secteurs?.map((secteur: ISector) =>
                      secteur?.bins?.map((bin: IBin) => (
                        <Marker
                          key={bin.id}
                          position={{
                            lat: Number(bin?.latitude),
                            lng: Number(bin?.longitude),
                          }}
                        />
                      ))
                    )}
                    {directions && (
                      <DirectionsRenderer
                        directions={directions}
                        // options={{
                        //   polylineOptions: {
                        //     strokeColor: 'red',
                        //   },
                        // }}
                      />
                    )}

                    <Stack gap={2}>
                      <IconButton
                        color="default"
                        aria-label="get Location"
                        onClick={handleLocationButtonClick}
                        sx={{
                          position: 'absolute',
                          top: '10px',
                          left: '10px',
                          zIndex: 100,
                        }}
                      >
                        <MyLocationIcon fontSize="large" />
                      </IconButton>
                      {/* <IconButton
                        color="default"
                        aria-label="get Location"
                        // onClick={handleLocationButtonClick}
                        sx={{
                          position: 'absolute',
                          top: '50px',
                          left: '10px',
                          zIndex: 100,
                        }}
                      >
                        <AssistantDirectionIcon fontSize="large" />
                      </IconButton> */}
                    </Stack>
                  </GoogleMap>
                )}
              </>
            </Box>
          </>
        );
      case 'bin':
        return <Bin />;
    }
  };
  ///////////////////////////////
  return (
    <>
      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          height: 90,
          py: 4,
          px: 3,
          zIndex: 5,
          borderTopRightRadius: '60px',
          borderTopLeftRadius: '60px',
          marginTop: 10,
        }}
        elevation={3}
      >
        <BottomNavigation
          sx={{ width: '100%', position: 'relative' }}
          value={value}
          onChange={handleChange}
        >
          <BottomNavigationAction
            value="history"
            icon={
              <RestoreIcon
                style={{
                  fontSize: 45,
                  position: 'absolute',
                  top: -50,
                  backgroundColor: value === 'history' ? '#1976D2' : '#FFF',
                  color: value === 'history' ? '#FFF' : '#1976D2',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                  padding: '8px',
                  borderRadius: '50%',
                }}
              />
            }
          />

          <BottomNavigationAction
            value="collect"
            icon={
              <DirectionsIcon
                style={{
                  fontSize: 70,
                  position: 'absolute',
                  top: -60,
                  backgroundColor: value === 'collect' ? '#1976D2' : '#FFF',
                  color: value === 'collect' ? '#FFF' : '#1976D2',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                  padding: '8px',
                  borderRadius: '50%',
                }}
              />
            }
            onClick={handleClickOpen}
          />
          <BottomNavigationAction
            value="bin"
            icon={
              <QrCodeIcon
                style={{
                  fontSize: 45,
                  position: 'absolute',
                  top: -50,
                  backgroundColor: value === 'bin' ? '#1976D2' : '#FFF',
                  color: value === 'bin' ? '#FFF' : '#1976D2',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                  padding: '8px',
                  borderRadius: '50%',
                }}
              />
            }
          />
        </BottomNavigation>
      </Paper>
      {renderPage()}
    </>
  );
};
