import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Button,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  StepIconProps,
  styled,
  StepConnector,
  stepConnectorClasses,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  SaveButton,
} from '@pankod/refine-mui';
import { TaskOutlined, QrCodeOutlined } from '@mui/icons-material';

import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import { IBin, IHistory, IReport, IRequest, IState } from 'interfaces/common';
import { ReactComponent as Water } from '../assets/icons/water.svg';
import { ReactComponent as QR } from '../assets/icons/qr.svg';
import {
  IResourceComponentsProps,
  useApiUrl,
  HttpError,
  useCustom,
  useCreate,
  useGetIdentity,
} from '@pankod/refine-core';
import { useStepsForm, FieldValues } from '@pankod/refine-react-hook-form';
import { QrScanner } from '@yudiel/react-qr-scanner';
import { ReactComponent as BlueBin } from '../assets/icons/blue_bin.svg';
import { ReactComponent as BinFull } from '../assets/BIN_full.svg';
import background_mobile_scan from '../assets/background_mobile_scan.svg';
import { Slider } from '@pankod/refine-mui';
import Webcam from 'react-webcam';

import { ReactComponent as BinEmpty } from '../assets/BIN_empty.svg';
import { ReactComponent as Bin10 } from '../assets/bin_levels/10BIn.svg';
import { ReactComponent as Bin20 } from '../assets/bin_levels/20BIn.svg';
import { ReactComponent as Bin30 } from '../assets/bin_levels/30BIn.svg';
import { ReactComponent as Bin40 } from '../assets/bin_levels/40BIn.svg';
import { ReactComponent as Bin50 } from '../assets/bin_levels/50BIn.svg';
import { ReactComponent as Bin60 } from '../assets/bin_levels/60BIn.svg';
import { ReactComponent as Bin70 } from '../assets/bin_levels/70BIn.svg';
import { ReactComponent as Bin80 } from '../assets/bin_levels/80BIn.svg';
import { ReactComponent as Bin90 } from '../assets/bin_levels/90BIn.svg';
import { useNavigate } from '@pankod/refine-react-router-v6';
import placeholderImg from '../assets/placeholder_empty.jpg';

//
interface ReportFormValues {
  option: string;
  note: string;
}
//
function valuetext(value: number) {
  return `${value}%`;
}

const marks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 10,
  },
  {
    value: 20,
  },
  {
    value: 30,
  },
  {
    value: 40,
  },
  {
    value: 50,
  },
  {
    value: 60,
  },
  {
    value: 70,
  },
  {
    value: 80,
  },
  {
    value: 90,
  },
  {
    value: 100,
    label: '100%',
  },
];

export const Bin: React.FC<IResourceComponentsProps> = () => {
  const {
    refineCore: { onFinish },

    watch,
    handleSubmit,
    setValue,

    steps: { currentStep, gotoStep },
  } = useStepsForm<IRequest, HttpError, IRequest>({
    stepsProps: {
      isBackValidate: false,
    },
    warnWhenUnsavedChanges: true,
  });
  const navigate = useNavigate();
  const [state, setState] = useState<IState>({
    delay: 100,
    result: '',
  });
  const handleScan = (dat: string | null) => {
    if (dat) {
      setState({ ...state, result: dat });
      gotoStep(1);
    }
  };
  const handleError = (err: Error) => {
    console.error(err);
  };
  ///////////
  const apiUrl = useApiUrl();
  const { data } = useCustom<IBin>({
    url: `${apiUrl}/bins/bin/${state.result}`,
    method: 'get',
  });

  const binDetails = data?.data;
  const level =
    (binDetails?.citizens_requests
      ?.map((fill) => fill.percentage)
      ?.pop() as number) || 0;

  ///////////
  // the Connetor
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 32,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor:
          theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#1976D2',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor:
          theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#1976D2',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    zIndex: 1,
    color: '#9E9E9E',
    width: 70,
    height: 70,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#1976D2',
      // boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      color: '#1976D2',
    }),
  }));
  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
      1: <QrCodeOutlined />,
      2: <Water />,
      3: <TaskOutlined />,
    };
    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  const [values, setValues] = React.useState<number>(level);

  const getColor = (val: number) => {
    if (val <= 50) {
      return 'primary.main';
    } else if (val <= 70) {
      return 'secondary.orange';
    } else {
      return 'secondary.main';
    }
  };

  const constraints = {
    facingMode: { exact: 'environment' },
  };
  const webcamRef = useRef<Webcam>(null);

  const [url, setUrl] = React.useState<string>('assets/placeholder_empty');

  useEffect(() => {
    setValue('percentage', values);
    setValue('image', url);
  }, [values, setValue, url]);

  // Repport Dialogue
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { mutate: report } = useCreate<IReport>();

  const { mutate: history } = useCreate<IHistory>();
  const { data: user } = useGetIdentity();
  const driverId = user && user.driver.id;

  const [formValues, setFormValues] = useState<ReportFormValues>({
    option: '',
    note: '',
  });
  const [selectedOption, setSelectedOption] = useState('');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, option: event.target.value });
    setSelectedOption(event.target.value);
  };

  const isFormValid =
    (formValues.option !== '' || formValues.note !== '') &&
    (formValues.option === '' || formValues.note === '');

  const handleSubmitReport = () => {
    report({
      resource: 'historiques/rapport',
      values: {
        srb: `${binDetails?.srb}`,
        pourcentage: level,
        probleme: formValues.option,
        note: formValues.note,
        driverId: driverId,
        binId: binDetails?.id,
      },
    });
  };

  //

  const steps = ['Scan', 'Fill Level', 'Emptying'];
  const renderFormByStep = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Box
            component="div"
            sx={{
              width: '100%',
              height: '100%',
              p: '16px',
            }}
          >
            <Stack
              justifyContent="space-between"
              spacing={3}
              alignItems="center"
              mt={3}
            >
              <Box
                component="div"
                borderBottom={7}
                borderTop={7}
                borderColor="primary.main"
                borderRadius={6}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: 280,
                  height: 220,
                  overflow: 'hidden',
                  zIndex: '200',
                }}
              >
                <QrScanner
                  scanDelay={state.delay}
                  onError={handleError}
                  onDecode={handleScan}
                  constraints={constraints}
                />
              </Box>

              <QR width={43} height={43} />
              <Typography fontSize="15px" color="#000" textAlign="center">
                Scannez le <span style={{ color: '#1976D2' }}>Code QR</span>
                <br /> sur la SRB
              </Typography>
            </Stack>
          </Box>
        );
      case 1:
        return (
          <Box
            component="div"
            sx={{
              width: '100%',
              backgroundColor: '#fff',
              boxShadow: '5',
              borderRadius: '32px',
              height: 'auto',
              px: '16px',
              py: '20px',
            }}
          >
            <Stack
              justifyContent="space-between"
              spacing={3}
              sx={{
                position: 'relative',
              }}
            >
              {/* The Card Header */}
              <Box component="div">
                <Stack direction="row" spacing={2} alignItems="center">
                  <BlueBin />
                  <Stack>
                    <Typography fontSize={20} sx={{ color: 'primary.main' }}>
                      SRB #{state.result}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
              {/* The Card Content */}
              <Box component="div" px={6}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={1}
                >
                  {values === 10 || level === 10 ? (
                    <Bin10 />
                  ) : values === 20 || level === 20 ? (
                    <Bin20 />
                  ) : values === 30 || level === 30 ? (
                    <Bin30 />
                  ) : values === 40 || level === 40 ? (
                    <Bin40 />
                  ) : values === 50 || level === 50 ? (
                    <Bin50 />
                  ) : values === 60 || level === 60 ? (
                    <Bin60 />
                  ) : values === 70 || level === 70 ? (
                    <Bin70 />
                  ) : values === 80 || level === 80 ? (
                    <Bin80 />
                  ) : values === 90 || level === 90 ? (
                    <Bin90 />
                  ) : values === 100 || level === 100 ? (
                    <BinFull />
                  ) : (
                    <BinFull />
                  )}
                  <Slider
                    // {...register('percentage')}
                    name="percentage"
                    sx={{
                      height: 230,
                      width: 8,
                      // color: getColor(level),
                      color: getColor(level !== undefined ? level : values),
                      // color: getColor(values !== undefined ? values : level),
                    }}
                    aria-label="FillLevel"
                    // value={level}
                    value={watch('percentage') || level}
                    defaultValue={level}
                    onChange={(event: Event, newValue: number | number[]) => {
                      setValues(newValue as number);
                    }}
                    getAriaValueText={valuetext}
                    valueLabelDisplay="auto"
                    orientation="vertical"
                    step={10}
                    marks={marks}
                    min={0}
                    max={100}
                  />
                </Stack>
              </Box>
              <Stack
                // alignItems="center"
                justifyContent="space-between"
                spacing={0.5}
                // mt={2}
              >
                <Stack direction="row" justifyContent="space-between" gap={2}>
                  <Typography fontSize={14} fontWeight={500} color="#000">
                    Type PDC:
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    color="primary.main"
                  >
                    {binDetails?.type_pdc?.type}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between" gap={2}>
                  <Typography fontSize={14} fontWeight={500} color="#000">
                    Secteur :
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    color="primary.main"
                  >
                    {binDetails?.secteur?.secteur}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between" gap={2}>
                  <Typography fontSize={14} fontWeight={500} color="#000">
                    Ville :
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    color="primary.main"
                  >
                    {binDetails?.secteur?.ville?.ville}
                  </Typography>
                </Stack>
              </Stack>
              {/* The Card CTA */}
              {/* Needs more styling */}
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={2}
                // mt={2}
                sx={{
                  width: '100%',
                }}
              >
                <Button
                  variant="contained"
                  startIcon={<GppMaybeIcon sx={{ color: 'error.main' }} />}
                  onClick={handleClickOpen}
                  sx={{
                    bgcolor: '#FFF',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                    py: '10px',
                    px: '20px',
                    width: '100%',
                    '&:hover': {
                      bgcolor: '#FFF',
                    },
                    '&:active': {
                      bgcolor: '#FFF',
                    },
                  }}
                >
                  <Typography fontSize={14} fontWeight={500} color="error.main">
                    Rapport
                  </Typography>{' '}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    textTransform: 'uppercase',
                    py: '10px',
                    px: '20px',
                    width: '100%',
                  }}
                  onClick={() => {
                    gotoStep(2);
                    history({
                      resource: 'historiques',
                      values: {
                        srb: `${binDetails?.srb}`,
                        pourcentage: values,
                        driverId: driverId,
                        binId: binDetails?.id,
                      },
                    });
                  }}
                >
                  Vidangé
                </Button>
              </Stack>
              {/* Repport */}
              <Dialog
                open={open}
                onClose={handleClose}
                sx={{
                  gap: 3,
                  width: '100%',
                }}
                fullWidth
              >
                <DialogTitle
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <GppMaybeIcon sx={{ color: 'error.main' }} />
                  <Typography fontSize={13} fontWeight={600} color="error.main">
                    Rapport
                  </Typography>{' '}
                </DialogTitle>
                <DialogContent
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <FormGroup sx={{ width: '100%' }}>
                    <FormControlLabel
                      label="Option 1"
                      control={
                        <Checkbox
                          value="Option 1"
                          onChange={handleCheckboxChange}
                          checked={selectedOption === 'Option 1'}
                        />
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="Option 2"
                          onChange={handleCheckboxChange}
                          checked={selectedOption === 'Option 2'}
                        />
                      }
                      label="Option 2"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="Option 3"
                          onChange={handleCheckboxChange}
                          checked={selectedOption === 'Option 3'}
                        />
                      }
                      label="Option 3"
                    />
                  </FormGroup>
                  <TextField
                    id="outlined-multiline-static"
                    label="Note"
                    multiline
                    rows={2}
                    fullWidth
                    value={formValues.note}
                    onChange={handleChange}
                    name="note"
                  />
                </DialogContent>
                <DialogActions sx={{ mb: 2, px: '24px' }}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleSubmitReport();
                      handleClose();
                    }}
                    sx={{
                      bgcolor: '#FFF',
                      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                      py: '10px',
                      px: '20px',
                      width: '100%',
                      '&:hover': {
                        bgcolor: '#FFF',
                      },
                      '&:active': {
                        bgcolor: '#FFF',
                      },
                    }}
                    disabled={!isFormValid}
                  >
                    <Typography
                      fontSize={13}
                      fontWeight={600}
                      color="error.main"
                    >
                      ENVOYER
                    </Typography>{' '}
                  </Button>
                </DialogActions>
              </Dialog>

              <Box></Box>
            </Stack>
          </Box>
        );
      case 2:
        return (
          <Box
            component="div"
            sx={{
              width: '100%',
              backgroundColor: '#fff',
              boxShadow: '5',
              borderRadius: '32px',
              height: 'auto',
              px: '16px',
              py: '20px',
            }}
          >
            <Stack
              justifyContent="space-between"
              spacing={6}
              sx={{
                position: 'relative',
              }}
            >
              {/* The Card Header */}
              <Box component="div">
                <Stack direction="row" spacing={2} alignItems="center">
                  <BlueBin />
                  <Stack>
                    <Typography fontSize={20} sx={{ color: 'primary.main' }}>
                      SRB #{state.result}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
              {/* The Card Content */}
              <Box component="div" px={6}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <BinEmpty />

                  <Slider
                    // {...register('percentage')}
                    name="percentage"
                    sx={{
                      height: 230,
                      width: 8,
                      // color: getColor(values),
                      // color: getColor(values !== undefined ? values : level),
                    }}
                    aria-label="FillLevel"
                    // value={watch('percentage') ?? level}
                    onChange={(event: Event, newValue: number | number[]) => {
                      setValues(newValue as number);
                    }}
                    defaultValue={0}
                    getAriaValueText={valuetext}
                    valueLabelDisplay="auto"
                    orientation="vertical"
                    step={10}
                    marks={marks}
                    min={0}
                    max={100}
                  />
                </Stack>
              </Box>

              <Box>
                <Button
                  variant="contained"
                  sx={{
                    textTransform: 'uppercase',
                    py: '10px',
                    px: '20px',
                    width: '100%',

                    mt: 2,
                  }}
                  onClick={handleSubmit(onFinishHandler)}
                >
                  Envoyer
                </Button>
              </Box>
            </Stack>
          </Box>
        );
    }
  };

  const onFinishHandler = async (data: FieldValues) => {
    await onFinish({
      ...data,
      binId: binDetails?.id,
      image: placeholderImg,
      percentage: 0,
    });
    navigate('/');
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        // minHeight: '100vh',
      }}
    >
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          backgroundImage: `url(${background_mobile_scan})`,
          backgroundSize: 'cover',
        }}
      >
        <Stepper
          alternativeLabel
          activeStep={currentStep}
          connector={<ColorlibConnector />}
          sx={{
            backgroundColor: '#fff',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '10px',
            boxShadow: '5',
          }}
        >
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};

            return (
              <Step {...stepProps} key={label}>
                <StepLabel {...labelProps} StepIconComponent={ColorlibStepIcon}>
                  {/* {label} */}
                </StepLabel>
                {/* <StepButton onClick={() => gotoStep(index)}>{label}</StepButton> */}
              </Step>
            );
          })}
        </Stepper>
        {/* <>
          {currentStep < steps.length - 1 && (
            <Button
              onClick={() => {
                gotoStep(currentStep + 1);
              }}
            >
              next
            </Button>
          )}
          {currentStep > 0 && (
            <Button
              onClick={() => {
                gotoStep(currentStep - 1);
              }}
            >
              prev
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <SaveButton onClick={handleSubmit(onFinishHandler)} />
          )}
        </> */}
        <Box
          component="main"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
            alignItems: 'center',
            height: '90vh',
            mt: '12px',
          }}
        >
          {renderFormByStep(currentStep)}
        </Box>
      </Box>
    </Box>
  );
};
